import Vue from 'vue';
import VueRouter from 'vue-router';
import ObjectCleaning from '@/views/ObjectCleaning';
import Cleaning from '../views/Cleaning.vue';
import CleaningMain from '../views/CleaningMain.vue';
import ObjectInfo from '../views/ObjectInfo.vue';
import ObjectRating from '../views/ObjectRating.vue';
import Page404 from '../views/Page404';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Cleaning',
    component: Cleaning,
    children: [
      {
        name: 'index',
        // при совпадении пути с шаблоном /
        // в <router-view> компонента Cleaning будет показан CleaningMain
        path: '',
        component: CleaningMain
      },
      // {
      //   // при совпадении пути с шаблоном /:id
      //   // в <router-view> компонента Cleaning будет показан CleaningItem
      //   path: ':id',
      //   // component: CleaningItem
      //   component: () => import(/* webpackChunkName: "item" */ '../views/CleaningItem.vue')
      // }
      {
        path: 'info/:id',
        name: 'info',
        component: ObjectInfo
      },
      {
        path: 'cleaning/:id',
        name: 'cleaning',
        component: ObjectCleaning
      },
      {
        name: 'load',
        path: 'load',
        component: CleaningMain
      },
      {
        path: 'raiting',
        name: 'raiting',
        component: ObjectRating
      },
      { path: '*', name: 'Page404', component: Page404 }
    ]
  }

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
