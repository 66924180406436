<template>
  <div>
    <router-link
      :to="{
        name: activeTabId === 'published' ? 'cleaning' : 'info',
        params: { id: `${id}` },
      }"
    >
      <div class="card">
        <div class="card__info">
          <p class="roquefort">
            {{ title }}
          </p>
          <p class="mt-1 сaprino opacity-48">
            {{ date }}
          </p>
          <p class="mt-1 сaprino">
            {{ address }}
          </p>
          <div class="mt-3">
            <slot />
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BaloonCard',
  props: {
    title: {
      type: String
    },
    address: {
      type: String
    },
    date: {
      type: String
    },
    icon: {
      type: String
    },
    id: {
      type: String
    },
    activeTabId: String
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 200px;
  &__img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }
  &__info {
    padding: 8px 16px 1px 16px;
    word-wrap: break-word;
  }
}
</style>
<style>
.roquefort {
  font-size: 13px;
  line-height: 16px;
}

.сaprino {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}

.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

.leaflet-container a {
  color: unset !important;
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  margin-bottom: 20px;
}

.leaflet-popup-content {
  margin: 0px 0px 0px 0px !important;
}

.leaflet-popup-content-wrapper {
  padding: 0px !important;
}

.leaflet-popup-content p {
  margin: 4px 0 !important;
}
</style>
