<template>
  <div>
    <div
      class="flex align-center sulguni mb-7 print pointer"
      @click="goLoad()"
    >
      <r-icon
        class="mr-2 mt-2px"
        icon="arrow-left"
        fill="rocky"
      />
      Назад
    </div>
    <loader v-if="isLoading" />
    <page404 v-else-if="notFound" />
    <template v-else-if="!notFound">
      <div
        class="culture mt-8"
      >
        <images
          :item="filteredObjects[0] ? filteredObjects[0]['photoResult0'] : ''"
          ref="images"
        />
        <div
          class="culture__cards"
          v-for="marker in filteredObjects"
        >
          <div>
            <div class="width mb-3">
              <div>
                <h2>{{ marker.title }}</h2>
              </div>
              <div
                class="ml-3"
              />
            </div>
            <div class="footer-cleaning">
              <div>
                <div
                  class="mr-6 eye flex sulguni align-center mt-3"
                  v-if="marker.address"
                >
                  <r-icon
                    icon="geopoint"
                    fill="rocky"
                    class="anie mr-2"
                  />
                  <div
                    class="ellipsis m--2">
                    {{ marker.address }}
                  </div>
                </div>
              </div>
              <div
                class="mr-6 eye flex sulguni align-center mt-3"
                v-if="marker.days"
              >
                <r-icon
                  icon="calendar"
                  fill="rocky"
                  class="anie mr-2 icon-placeholder"
                />
                <div
                  class="m--2"
                >
                  {{ marker.days }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cleaning">
          <div
            class="culture__map-cont mt-10 mr-10"
          >
            <div v-for="marker in filteredObjects">
              <img
                :src="marker.photo0"
                v-if="marker.photo0"
                class="mb-6 w-100 br-16"
              >
            </div>
            <div
              class="description mb-6 justify"
              v-for="marker in filteredObjects"
            >
              {{ marker.comment }}
            </div>
            <div class="culture__map">
              <rir-map-leaflet
                v-if="isShowMap"
                :center="centerCoords"
                :search-in="false"
              >
                <l-marker
                  :lat-lng="[`${marker.lan}`, `${marker.lng}`]"
                  v-for="marker in filteredObjects"
                >
                  <l-icon
                    :icon-url="$markerIcon('cleaning_' + event(marker)).imageHref"
                  />
                </l-marker>
              </rir-map-leaflet>
            </div>
            <div class="marg">
              <div class="flex">
                <div
                  v-for="marker in filteredObjects"
                >
                  <div class="autor">
                    Автор мероприятия
                  </div>
                  <div
                    class="flex"
                    style="display: grid;grid-template-columns: auto 300px;"
                  >
                    <picture
                      class="mr-4 mt-3 mb-8 ml-4"
                      v-if="marker.userIco !== ''"
                    >
                      <img
                        class="w-56 br-30"
                        :src="marker.userIco"
                        style=" width: 56px;border-radius: 30px"
                      >
                    </picture>
                    <picture
                      class="mr-1 mt-3 mb-8"
                      v-else
                    >
                      <img
                        src="../../public/photo.svg"
                        class="mt-1 mr-2"
                      >
                    </picture>
                    <div class="mt-7" style="min-width: 88px">
                      <div class="title">
                        {{ marker.userFio }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="table ml-4 mt-4"
                  style="width: 250px;right: 10px"
                >
                  <div v-for="marker in filteredObjects">
                    <div class="autor mt__14px">
                      Присоединились
                    </div>
                    <div class="w-400">
                      <picture
                        @click="participantsModal(filteredObjects[0].participants)"
                        v-for="(item,index) in filteredObjects[0].participants"
                        v-if="filteredObjects[0].participants && index < 6"
                        class="mr-4 mt-8 mb-8"
                      >
                        <img
                          :src="'/mob_ajax.php?getAvatarForUser=' + item.userId"
                          :class="index === 0 ? 'icon-from' : 'icon-from left-icon'"
                        >
                      </picture>
                      <div
                        v-if="userId && event(marker) === 'wait'"
                        class="mt-8 flex subscribe"
                        @click="addUser()"
                      >
                        <r-icon
                          class="mr-2"
                          icon="add"
                          fill="rocky"
                        />
                        Присоединиться
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <r-tabs
                class="mt-7 w-100"
                :items="tabItems"
                v-model="activeTabId"
              />
              <div
                v-if="itemsComment || (filteredObjects[0] && filteredObjects[0]?.commentResult)"
              >
                <div
                  class="mt-9 d-grid w-100"
                  v-if="filteredObjects[0]?.commentResult !== ''"
                >
                  <div>
                    <div
                      class="title-text"
                    >
                      Итоги проведения от {{ filteredObjects[0]?.createdF }}
                    </div>
                  </div>
                  <div class="mt-4 flex">
                    <picture
                      class="mr-4"
                      v-if="filteredObjects[0]?.userIco !== ''"
                    >
                      <img
                        v-if="filteredObjects[0]?.userIco"
                        :src="filteredObjects[0].userIco"
                        style=" width: 56px; margin-top: -8px;border-radius: 30px"
                      >
                    </picture>
                    <picture
                      class="mr-1 mt-3"
                      v-else
                    >
                      <img
                        src="../../public/photo.svg"
                        class="mt-1 mr-2"
                      >
                    </picture>
                    <div class="mt-6">
                      <div
                        class="title"
                        v-if="filteredObjects[0]?.user"
                      >
                        {{ filteredObjects[0].user.fio }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-2 comment justify"
                  >
                    {{ filteredObjects[0]?.commentResult ? filteredObjects[0].commentResult : '' }}
                  </div>
                  <div v-if="filteredObjects[0]">
                    <img
                      :src="filteredObjects[0]['photoResult0']"
                      class="mt-2 h-56 br-8 pointer"
                      @click="showImg"
                    >
                  </div>
                </div>
                <div
                  class="mt-9 d-grid w-100"
                  v-for="item in itemsComment"
                >
                  <div>
                    <div
                      class="title-text"
                    >
                      Комментарий от {{ item.createdF }}
                    </div>
                  </div>
                  <div
                    class="mt-4 flex"
                    v-if="item.ico && item.fio"
                  >
                    <picture
                      class="mr-4"
                    >
                      <img
                        :src="item.ico"
                        class="w-56 mt-2 br-30"
                      >
                    </picture>
                    <div class="mt-6">
                      <div class="title">
                        {{ item.fio }}
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="mt-5">
                      <div class="title">
                        Аноним
                      </div>
                    </div>
                  </div>

                  <div
                    class="mt-2 comment justify"
                  >
                    {{ item.comment }}
                  </div>
                </div>
              </div>
              <div
                class="mt-6 text-comments"
                v-else
              >
                Комментариев пока нет, станте первым
              </div>
              <r-textarea
                class="mb-6 mt-9"
                value=""
                label="Текст комментария"
                :rows="6"
                :max-rows="6"
                v-model="comment"
              />
              <div
                class="flex mt-2 mb-4 w-100 d-block"
              >
                <r-button
                  class="flex-1"
                  :disabled="comment === ''"
                  @click="save"
                  width="wide"
                  title="Оставить комментарий"
                />
              </div>
            </div>
          </div>
          <div
            class="cleaning__map-cont"
            v-for="marker in filteredObjects"
          >
            <div class="time-mer mb-6">
              Этапы мероприятия
            </div>
            <div class="flex">
              <div class="points">
                <div
                  v-if="marker.approved === 1
                    || events(marker)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill="#3D75E4"
                    />
                  </svg>
                  <div>
                    <div class="ml-1 points__down" />
                  </div>
                </div>
                <div v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill="#E4EDFB"
                    />
                  </svg>
                  <div>
                    <div class="ml-1 points__down" />
                  </div>
                </div>

                <div
                  v-if="events(marker)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill="#3D75E4"
                    />
                  </svg>
                  <div>
                    <div class="ml-1 points__down" />
                  </div>
                </div>
                <div v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill="#E4EDFB"
                    />
                  </svg>
                  <div>
                    <div class="ml-1 points__down" />
                  </div>
                </div>
                <div
                  v-if="event(marker) === 'progress'
                    || event(marker) === 'done'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill="#3D75E4"
                    />
                  </svg>
                  <div>
                    <div class="ml-1 points__down" />
                  </div>
                </div>
                <div v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill="#E4EDFB"
                    />
                  </svg>
                  <div>
                    <div class="ml-1 points__down" />
                  </div>
                </div>

                <svg
                  v-if="event(marker) === 'done'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="#3D75E4"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="#E4EDFB"
                  />
                </svg>
              </div>
              <div>
                <div
                  class="mb-8 margin-busTwo"
                  v-if="marker.approved === 1
                    || events(marker)"
                >
                  Мероприятие одобрено
                </div>
                <div
                  class="mb-8 margin-bus"
                  v-else
                >
                  Мероприятие одобрено
                </div>
                <div
                  class="mb-8 margin-busTwo"
                  v-if="events(marker)"
                >
                  Ожидается уборка
                </div>
                <div
                  class="mb-8 margin-bus"
                  v-else
                >
                  Ожидается уборка
                </div>
                <div
                  class="mb-8 margin-busTwo"
                  v-if="event(marker) === 'progress'
                    || event(marker) === 'done'"
                >
                  Идет уборка
                </div>
                <div
                  class="mb-8 margin-bus"
                  v-else
                >
                  Идет уборка
                </div>
                <div
                  class="mb-8 margin-busTwo"
                  v-if="event(marker) === 'done'"
                >
                  Уборка
                  завершена
                </div>
                <div
                  class="mb-8 margin-bus"
                  v-else
                >
                  Уборка завершена
                </div>
              </div>
            </div>
            <div
              class="mt-4 ml-4"
            >
              <h2
                class="taleggio title__share mb-3 flex"
              >
                Поделиться
              </h2>
              <share class="mb-3" />
            </div>
            <div
              class="end"
              v-if="!marker.commentResult && result
              && event(marker) === 'done'"
            >
              <div class="itog">
                Итоги проведения
              </div>
              <div class="itog__text mt-2">
                Уборка завершена. Поделитесь
                своими результатами и впечатленями.
              </div>
              <div>
                <r-button
                  class="mt-4 itog__button"
                  @click="clickUpload"
                  width="wide"
                  title="Отправить результат"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="margOne">
        <div class="flex flex-column">
          <div
            class="mt-4 mb-4"
            v-for="marker in filteredObjects"
          >
            <div class="autor">
              Автор мероприятия
            </div>
            <div class="flex mt-4 align-center">
              <picture v-if="marker.userIco !== ''">
                <img
                  :src="marker.userIco"
                  class="w-56 br-30"
                >
              </picture>
              <picture v-else>
                <img src="../../public/photo.svg">
              </picture>
              <div
                class="title ml-4 mt-4"
                style="min-width: 88px"
              >
                {{ marker.userFio }}
              </div>
            </div>
          </div>
          <div v-for="marker in filteredObjects">
            <div class="autor ico-hide mt-4">
              Присоединились
            </div>
            <div class="mt-9">
              <picture
                @click="participantsModal(filteredObjects[0].participants)"
                v-for="(item,index) in filteredObjects[0].participants"
                v-if="filteredObjects[0].participants && index < 6"
                class="mr-4 mt-8 mb-8"
              >
                <img
                  :src="'/mob_ajax.php?getAvatarForUser=' + item.userId"
                  :class="index === 0 ? 'icon-from mt-6' : 'icon-from left-icon mt-6'"
                >
              </picture>
              <div
                class="mt-8 flex subscribe float-none"
                v-if="userId
                && event(marker) === 'wait'"
                @click="addUser()"
              >
                <r-icon
                  class="mr-2"
                  icon="add"
                  fill="rocky"
                />
                Присоединиться
              </div>
            </div>
          </div>
        </div>

        <r-tabs
          class="mt-7"
          :items="tabItems"
          v-model="activeTabId"
        />
        <div
          style="margin-top: 36px;display: inline-grid; width: 100%"
          v-if="filteredObjects[0] && filteredObjects[0].commentResult !== ''"
        >
          <div>
            <div
              class="title-text"
            >
              Итоги проведения от {{ filteredObjects[0].createdF }}
            </div>
          </div>
          <div class="mt-4 flex">
            <picture
              class="mr-4"
              v-if="filteredObjects[0].userIco !== ''"
            >
              <img
                v-if="filteredObjects[0].userIco"
                :src="filteredObjects[0].userIco"
                class="w-56 mt-2 br-30"
              >
            </picture>
            <picture
              class="mr-1 mt-3"
              v-else
            >
              <img
                src="../../public/photo.svg"
                class="mt-1 mr-2"
              >
            </picture>
            <div class="mt-6">
              <div class="title">
                {{ filteredObjects[0].user.fio }}
              </div>
            </div>
          </div>
          <div
            class="mt-2 comment justify"
          >
            {{ filteredObjects[0].commentResult }}
          </div>
          <div v-if="filteredObjects[0]['photoResult0']">
            <img
              :src="filteredObjects[0]['photoResult0']"
              style="border-radius: 8px; height: 56px;cursor: pointer"
              class="mt-2 pointer"
              @click="showImg"
            >
          </div>
        </div>

        <div v-if="itemsComment">
          <div
            class="mt-9 d-grid w-100"
            v-for="item in itemsComment"
          >
            <div>
              <div
                class="title-text"
              >
                Комментарий от {{ item.createdF }}
              </div>
            </div>
            <div
              class="mt-4 flex"
              v-if="item.ico && item.fio"
            >
              <picture
                class="mr-4"
              >
                <img
                  :src="item.ico"
                  class="w-56 mt-2 br-30"
                >
              </picture>
              <div class="mt-6">
                <div class="title">
                  {{ item.fio }}
                </div>
              </div>
            </div>
            <div v-else>
              <div class="mt-5">
                <div class="title">
                  Аноним
                </div>
              </div>
            </div>

            <div
              class="mt-2 comment justify"
            >
              {{ item.comment }}
            </div>
          </div>
        </div>
        <div
          class="mt-6 text-comments"
          v-else-if="filteredObjects[0].commentResult === ''"
        >
          Комментариев пока нет, станте первым
        </div>
        <r-textarea
          class="mb-6 mt-9"
          value=""
          label="Текст комментария"
          :rows="6"
          :max-rows="6"
          v-model="comment"
        />
        <div
          class="flex mt-2 mb-4 w-100 d-block"
        >
          <r-button
            class="flex-1"
            :disabled="comment === ''"
            @click="save"
            width="wide"
            title="Оставить комментарий"
          />
        </div>
      </div>
    </template>
    <upload-itog
      :item="allObjects"
      ref="upload"
    />
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>
<script>
import { LMap, LTileLayer, LMarker, LPolyline, LIcon, LPopup } from 'vue2-leaflet';
import L from 'leaflet';
import RirMapLeaflet from '@/components/RirMapLeaflet.vue';
import Loader from '@/components/Loader.vue';
import Share from '../components/Share.vue';
import UploadItog from '../components/UploadItog.vue';
import Images from '../components/Image.vue';
import Page404 from './Page404';
import UserModal from "@/components/UserModal.vue";

export default {
  components: {
    Loader,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LIcon,
    LPopup,
    RirMapLeaflet,
    Share,
    UploadItog,
    Images,
    Page404,
    UserModal
  },
  props: {},
  data() {
    return {
      centerCoords: [64.91185059862988, 77.78357681226097],
      isShowMap: false,
      coordinates: [64.91185059862988, 77.78357681226097],
      activeTabId: {
        id: 'comments',
        title: 'Комментарии',
        count: 0
      },
      itemsComment: [],
      comment: '',
      dayEnd: null,
      timeTo: null,
      timeFrom: null,
      result: false,
      resultUser: null,
      users: null,
      userId: true,
      usersId: [],
      userFunc: {},
      userIdEMKA: false,
      notFound: false
    };
  },

  computed: {
    tabItems() {
      const tabs = [
        {
          id: 'comments',
          title: 'Комментарии',
          count: 0
        }
      ];
      return tabs;
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    },
    allObjects() {
      if (!this.$store.state?.activeShow?.all?.length) return [];

      const objects = this.$store.state.activeShow.all;
      return objects
        .map(el => {
          this.centerCoords = [el.lat ? el.lat : 64.92275052316926, el.lng ? el.lng : 77.77823567390442];
          this.users = el.user;
          this.userFunc = el.user;
          this.userId = true;
          this.day = el.eventForm?.datesExtra ? el.eventForm.datesExtra[0] : '-';
          this.dayEnd = el.eventForm?.datesExtra ? el.eventForm.datesExtra[el.eventForm.datesExtra.length - 1] : '-';
          if (el.eventForm?.time) {
            const time = el.eventForm?.time.split(' - ');
            this.timeTo = this.formatDateHour(time[0]);
            this.timeFrom = this.formatDateHour(time[1]);
          }
          el.participants = el.participants ? el.participants.filter(els => els !== null) : false;
          if (el.participants && this.usersId.user) {
            el.participants.forEach(item => {
              if (item.userIdEMKA === this.usersId.user.id) {
                this.userId = false;
              }
              if (item.userIdEMKA === el.user.userIdEMKA ) {
                this.userIdEMKA = true;
              }
            });
          }
          if (this.users && this.usersId.user) {
            if (this.usersId.user.id === this.users.userIdEMKA) {
              this.result = true;
            }
          }
          if (!this.usersId.user) {
            this.userId = false;
          }
          if (el?.id) {
            let days = [];
            if(el?.eventForm?.datesExtra) {
              el.eventForm.datesExtra.map(elem => {
                days.push(new Date(elem).toLocaleDateString('ru-Ru'))
              });
            }
            let timeTo = null;
            let timeFrom = null;
            if(el.eventForm?.time) {
              const time = el.eventForm?.time.split(' - ');
              timeTo = this.formatDateHour(time[0]);
              timeFrom = this.formatDateHour(time[1]);
            }
            return {
              id: el.id,
              timeTo: timeTo,
              timeFrom: timeFrom,
              address: el.eventForm ? el.eventForm.address : '-',
              dayF: el.eventForm?.datesExtra ? el.eventForm.datesExtra[0] : '-',
              dayFEnd: el.eventForm?.datesExtra ? el.eventForm.datesExtra[el.eventForm.datesExtra.length - 1] : '-',
              days: el.eventForm?.datesExtra ? days.join() : '-',
              title: el.title,
              lan: el.lat ? el.lat : 64.92275052316926,
              lng: el.lng ? el.lng : 77.77823567390442,
              comment: el.comment,
              photo0: el.photo0,
              userIco: `/mob_ajax.php?getAvatarForUser=${el.userId}`,
              userFio: el.user ? el.user.fio : '',
              cityId: el.cityId,
              commentResult: el.commentResult,
              created: el.created,
              createdF: el.createdF,
              details: el.details,
              eventForm: el.eventForm,
              eventStatus: el.eventStatus,
              hidden: el.hidden,
              participants: el.participants ? el.participants.filter(els => els !== null) : false,
              lat: el.lat,
              mine: el.mine,
              photo1: el.photo1,
              photo2: el.photo2,
              photoResult0: el.photoResult0,
              photoResult1: el.photoResult1,
              photoResult2: el.photoResult2,
              user: el.user
            };
          }
        });
    },
    filteredObjects() {
      const list = this.allObjects;

      return list;
    }
  },
  async mounted() {
    await this.$store.dispatch('loadActivityId', {
      id: this.$route.params.id
    }).then(res => {
      if (!res?.all?.length) {
        this.notFound = true;
      }
    });
    this.user();
    this.isShowMap = true;
  },
  created() {
    this.$store.dispatch('loadComments', { id: this.$route.params.id }).then(result => {
      let key = 0;
      result.all.forEach(item => {
        result.all[key].ico = `/mob_ajax.php?getAvatarForUser=${item?.userId}`;
        key++;
      });

      this.itemsComment = result.all;

      this.itemsComment = this.itemsComment.filter(el => el.hidden === 0);
    });
  },
  methods: {
    event(marker) {
      return (marker.dayF === '-' ? 'done' : this.getStatus(marker.dayF, marker.dayFEnd, marker.timeTo, marker.timeFrom));
    },
    events(marker) {
      return this.event(marker) === 'wait'
        || this.event(marker) === 'progress'
        || this.event(marker) === 'done';
    },
    formatDateHour(time) {
      let hh = time.split(':')[0];
      if (hh < 10) hh = hh.substring(1);
      const mm = time.split(':')[1];

      return `${hh}:${mm}`;
    },
    participantsModal(participants) {
      this.$refs.modal.openModal(UserModal, {
        participants: participants,
        user: this.userFunc,
        add: this.userId,
        wait: (this.getStatus(this.day, this.dayEnd, this.timeTo, this.timeFrom) === 'wait'),
        userIdEMKA: this.userIdEMKA
      });
    },
    goLoad() {
      const r = this.$router.resolve({
        name: 'load',
        params: {}
      });

      window.location.assign(r.href);
    },
    addUser() {
      this.$store.dispatch('getUserAdd', this.$route.params.id).then(result => {
        location.reload();
      }, error => {
        console.error(error);
      });
    },
    user() {
      this.$store.dispatch('user').then(result => {
        if (this.users !== null) {
          if (result.user.id === this.users.userIdEMKA) {
            this.result = true;
          }
        }
        this.usersId = result;
      }, error => {
        console.error(error);
      });
    },
    showImg() {
      this.$refs.images.showImages();
    },
    clickUpload() {
      this.$refs.upload.uploadContainer();
    },
    save() {
      this.$store.dispatch('saveComment', {
        id: this.$route.params.id,
        comment: this.comment
      }).then(result => {
        location.reload();
      }, error => {
        console.error(error);
      });
    },
    getStatus(date, dateEnd, timeTo, timeFrom) {
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 18;
      if (timeTo && timeFrom) {
        from = timeTo.split(':')[0];
        to = timeFrom.split(':')[0];
      }
      let status = 'wait';
      const hours = new Date().getHours();
      if (toDay >= date && toDay <= dateEnd) {
        status = 'progress';
        if (toDay === date) {
          if (hours < from) {
            status = 'wait';
          }
        }

        if (toDay === dateEnd) {
          if (hours < from) {
            status = 'progress';
          }
          if (hours > to) {
            status = 'done';
          }
        }
      }

      if (new Date(dateEnd) < new Date()) {
        status = 'done';
      }

      if(date === '-') {
        status = 'done';
      }

      return status;
    }
  }
};
</script>

<style lang="scss" scoped>
.subscribe {
  color: #3D75E4;
  cursor: pointer;
}

.icon-from {
  width: 56px;
  margin-top: -24px;
  border-radius: 28px;
  border: 3px solid #ffffff;
}

.left-icon {
  margin-left: -50px;
}

.itog {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #04153E;
  }

  &__button {
    cursor: pointer;
    width: 200px;
    display: table;
  }
}

.end {
  padding: 24px;
  height: 180px;
  max-width: 250px;
  background: #F6F9FE;
  border-radius: 16px;
  display: flow-root
}

.autor {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.comment {
  overflow: hidden;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
  padding-right: 12px;
  width: 90%;
  padding-top: 12px;
}

.marg {
  margin-top: 1px;
  width: 100%;
  display: table;
}

.margOne {
  display: none;
  width: 100%;
}

.text-comments {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}

.title__share {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #04153E;
}

.time-mer {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #04153E;
}

.margin-bus {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.48;
}

.margin-busTwo {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #3D75E4;
}

.points {
  width: 20px;
  height: 195px;
  display: table;
  margin-bottom: 1px;

  &__item {
    margin-left: 6px;
    width: 16px;
    height: 60px;
    background: #FFFFFF;
    border-left: 4px dashed #C0D6F6;
  }

  &__up {
    margin-left: 6px;
    width: 16px;
    margin-top: -1px;
    height: 20px;
    background: #FFFFFF;
    border-left: 4px solid #C0D6F6;
  }

  &__downTwo {
    margin-left: 8px;
    width: 16px;
    margin-top: -1px;
    height: 32px;
    background: #FFFFFF;
    border-left: 2px solid #3D75E4;
  }

  &__down {
    margin-left: 8px;
    width: 16px;
    margin-top: -1px;
    height: 32px;
    border-left: 2px solid #E4EDFB;
  }

  &__down-end {
    margin-left: 6px;
    width: 16px;
    height: 60px;
    background: #FFFFFF;
    border-left: 4px dashed #81ABEE;
  }
}

.card_hidden {
  display: none;
}

.card_hidden_down {
  display: table;
}

.opacity {
  color: #04153E;
  opacity: 0.48;
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.width {
  display: table;
  width: 100%;
}

.culture {
  max-width: 100%;
  flex-direction: column-reverse;

  &__cards {
    width: 100%;
    min-height: 100px;
  }

  &__map-cont {
    position: relative;
    top: unset;
    height: unset;
    padding: 0;
    margin-left: unset;
    width: 70%;
  }

  &__map {
    height: 300px;
    margin-bottom: 32px;
  }

  &__map--collapsed {
    height: 112px;
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: block;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dashed #214eb052;
    }
  }
}

.balloon-staff {
  display: grid;
  grid-template-columns: auto 1fr;
}

@media screen and (max-width: 930px) {
  .marg {
    display: none;
  }
  .margOne {
    width: 100%;
    display: table;
  }
  .card_hidden {
    display: table;
  }
  .card_hidden_down {
    display: none;
  }
  .culture {
    &__map-cont {
      position: relative;
      top: unset;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }
  }
}

@media screen and (min-width: 600px) {
  .culture {
    &__input-search {
      position: absolute;
      z-index: 10;
      background-color: #fff;
      box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
      top: 16px;
      left: 16px;
    }
  }
}

@media screen and (max-width: 700px) {
  .margOne {
    width: 100%;
    display: table;
  }
  .culture {
    &__map--collapsed {
      height: 144px;
    }

    &__map-cont {
      position: relative;
      top: unset;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }
  }
  .card_hidden_down {
    display: none;
  }
  .card_hidden {
    display: table;
  }
}

.other-icon:hover::after {
  position: absolute;
  content: attr(data-title); /* Выводим текст */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  margin-top: 30px;
  margin-left: -30px;
  width: 75px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.style-marin-hous {
  ont-family: Golos UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}

.description {
  ont-family: Golos UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

cleaning {
  display: flex;
  justify-content: space-between;

  &__input-search {
    display: none;
  }

  &__cards {
    width: 41.7%;
  }

  &__map-cont {
    width: 100%;
  }

  &__map {
    position: relative;
    display: flex;
    height: 648px;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }

  &__map-collapse-btn {
    display: none;
  }
}
.pointer {
  cursor: pointer;
}

.wbr {
  width: 100%;
  border-radius: 16px;
}

.table {
  display: table;
}

.mt__14px {
 margin-top: -14px;
}
.w-100 {
  width: 100%;
}

.d-grid {
  display: inline-grid;
}

.justify {
  text-align: justify;
}

.w-400 {
  width: 400px;
}

.br-8 {
  border-radius: 8px;
}

.h-56 {
  height: 56px;
}

.w-56 {
  width: 56px;
}

.br-30 {
  border-radius: 30px;
}

.d-block {
  display: inline-block;
}

.flex__1 {
  flex: 1;
}

.br-16 {
  border-radius: 16px;
}

.ellipsis {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer-cleaning {
  display: flex;
}

.cleaning {
  display: flex;
}

@media screen and (max-width: 800px) {
  .footer-cleaning {
    display: unset;
  }

  .cleaning {
    display: unset;
  }
}
@media screen and (max-width: 800px) {
  .style-marin-hous {
    width: 100%;
  }
  .ellipsis {
    max-width: 300px;
  }
  .mtop {
    margin-top: 12px;
  }
}
</style>
<style>
textarea {
  background: unset !important;
}
</style>
