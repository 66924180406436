<template>
  <div class="card">
    <div class="card__info">
      <h3 class="card__title">
        {{ address }}
      </h3>
      <p class="card__address">
        {{ type }}
      </p>
      <div class="mt-1 flex">
        <r-icon
          v-if="icon == 'progress'"
          icon="waiting"
          class="mr-1"
          fill="rocky"
        />
        <r-icon
          v-if="icon == 'done'"
          icon="selected"
          class="mr-1"
          fill="rocky"
        />
        <r-icon
          v-if="icon == 'wait'"
          icon="clock"
          class="mr-1"
          fill="rocky"
        />
        <p class="card__address rocky--text">
          {{ date }}
        </p>
      </div>
      <div
        v-if="$slots.default"
        class="card__additional"
      >
        <r-icon
          v-if="icon"
          :icon="icon"
        />

        <div class="card__additional-info">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'address', 'img', 'date', 'icon', 'type']
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  margin-bottom: 32px;
  &:hover {
    * {
      color: var(--rir-godfather);
    }
  }
  &__img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    min-height: 56px;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    margin-right: 16px;
    background-color: var(--rir-arrival);
    overflow: hidden;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
    overflow: hidden;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--rir-titanic);
    margin-right: 10px;
  }
  &__address {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: var(--rir-titanic);
    opacity: 0.72;
    margin-left: 4px;
  }
  &__additional {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }
  &__additional-info > * {
    margin-left: 6px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--rir-rocky);
  }
}
</style>
