<template>
  <div>
    <r-tabs
      class="mt-7"
      :items="tabItems"
      v-model="activeTabId"
      @change="changeTab"
    />

    <div class="flex align-center justify-between mt-16px mobile_grid">
      <tag-carousel
        :items="isModeration ? cleaningTypesMer : cleaningTypes"
        @select="onChangeType"
      />
      <div class="flex">
        <drop-down-button
          :items="isModeration ? periodListMer : periodList"
          @select="onChangePeriodMer"
          v-if="activeTabId.id === 'moderation'"
        />
        <r-popover
          v-if="activeTabId.id === 'published'"
          v-model="activePicker"
          class="mb-3"
          position="right-bottom"
        >
          <template #activator>
            <r-button-action
              v-model="activePicker"
              is-arrow
              :title="datePeriod || 'Дата уборки'"
              icon="calendar"
            />
          </template>

          <template #content>
            <div
              class="pa-4"
              id="picker"
            >
              <r-date-picker
                :click-close="true"
                v-model="date"
                inline
                @input="val => changeDate(val)"
              />
            </div>
          </template>
        </r-popover>
        <drop-down-button-status
          :titles="selectedStatus"
          :items="periodStatus"
          class="ml-4"
          @select="onChangeStatus"
          v-if="activeTabId.id === 'published'"
        />
      </div>
    </div>
    <div
      class="mt-6 flex align-items-center rocky--text pointer"
      v-if="activeTabId.id === 'moderation' && allowCreatePublic"
      @click="AddClick "
    >
      <r-icon
        class="mr-2"
        icon="add"
        fill="rocky"
      />
      Добавить мероприятие
    </div>

    <loader v-if="isLoading" />
    <div
      v-else
      class="cleaning mt-8"
    >
      <div
        class="cleaning__cards2"
        v-if="activeTabId.id === 'published'"
      >
        <router-link
          v-for="item in cleaningList"
          :key="item.id"
          :to="{
            name: 'cleaning',
            params: { id: item.id },
          }"
        >
          <clear-card
            :name="item.title"
            :address="item.address"
            :date="item.dayF"
            :img="item.photo0"
            :type="item.type === 'street' ? 'Улица' : (item.type === 'territory' ? 'Территория' : 'Двор')"
            :icon="getCleaningStatus(item.day, item?.details)"
          />
        </router-link>
        <div
          class="mt-4 mozzarella metropolis--text"
          v-if="cleaningList.length === 0"
        >
          На выбранную дату уборки не запланированы
        </div>
      </div>
      <div
        class="cleaning__cards2"
        v-if="activeTabId.id === 'moderation'"
      >
        <router-link
          v-for="item in filteredObjects"
          :key="item.id"
          :to="{
            name: 'info',
            params: { id: item.id },
          }"
        >
          <cleaning-card
            :name="item.title"
            :address="item.address"
            :date="item.dayF"
            :img="item.photo0"
            :icon="item.dayF === '-' ? 'done' : getStatus(item.dayF, item.dayFEnd, item.timeTo, item.timeFrom)"
          />
        </router-link>
      </div>

      <div
        class="cleaning__map-cont2"
        v-if="activeTabId.id === 'moderation'"
      >
        <pre v-if="!!$route.query.devmode">
          {{ filteredObjects }}
        </pre>
        <div :class="['cleaning__map2', { 'cleaning__map--collapsed2': isMapCollapsed }]">
          <rir-map-leaflet
            v-if="isShowMap"
            v-model="search"
            ref="myMap"
          >
            <v-marker-cluster
              :options="{chunkedLoading: false}"
            >
              <div v-if="filteredObjects.length > 0">
                <l-marker
                  :lat-lng="[`${marker.lan}`, `${marker.lng}`]"
                  v-for="marker in filteredObjects"
                >
                  <l-icon
                    :icon-url="$markerIcon('cleaning_' + (marker.dayF === '-' ? 'done' : getStatus(marker.dayF, marker.dayFEnd, marker.timeTo, marker.timeFrom))).imageHref"
                  />
                  <l-popup
                    :options="{offset: [110, -15]}"
                  >
                    <balloon-card
                      :id="marker.id"
                      :title="marker.title"
                      :date="marker.dayF"
                      :address="marker.address"
                      :icon="marker.dayF === '-' ? 'done' : getStatus(marker.dayF, marker.dayFEnd, marker.timeTo, marker.timeFrom)"
                      active-tab-id="moderation"
                    />
                  </l-popup>
                </l-marker>
              </div>
            </v-marker-cluster>
          </rir-map-leaflet>
          <button
            class="cleaning__map-collapse-btn2 feta"
            @click="onMapCollapsed()"
          >
            <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
        </div>
        <div class="mt-3 flex align-center pointer">
          <r-icon
            icon="info"
            fill="rocky"
          />
          <p
            class="ml-2 sulguni rocky--text"
            @click="onInform"
          >
            Сообщить о скоплении мусора
          </p>
        </div>
        <div
          class="ball mt-3"
          v-if="ball && showScore"
        >
          <div  style="display: grid;grid-template-columns: 50px auto;">
            <div
              class="ml-5 mr-3 mb-4 pt-5 h-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="32"
                viewBox="0 0 20 32"
                fill="none"
              >
                <path
                  d="M4.00001 0H16C17.1046 0 18 0.89543 18 2V6.3406C18 7.14066 17.5232 7.86374 16.7879 8.17889L13.0189 9.79414C12.5113 10.0117 11.9234 9.77655 11.7059 9.26892C11.4883 8.76129 11.7235 8.17341 12.2311 7.95585L16 6.3406V2H4.00001V6.3406L9.78785 8.82111C10.5232 9.13626 11 9.85934 11 10.6594V13L13.8205 17.9728L18.8388 18.9853C19.5834 19.1356 20 19.8018 20 20.4445C20 20.8086 19.8567 21.1699 19.58 21.4709L16.1816 25.2398L16.7694 30.3254C16.9036 31.4862 15.7176 32.3478 14.6551 31.8615L10 29.731L5.34494 31.8615C4.28241 32.3478 3.09646 31.4862 3.23061 30.3254L3.81837 25.2397L0.420014 21.4709C0.143298 21.1699 3.05863e-06 20.832 0 20.4445C-5.26472e-06 19.7773 0.41657 19.1356 1.16122 18.9853L6.17955 17.9728L9.00001 13V10.6594L3.21217 8.17889C2.47681 7.86374 2.00001 7.14066 2.00001 6.3406V2C2.00001 0.895431 2.89544 0 4.00001 0ZM7.80948 19.1542C7.59543 19.534 7.22676 19.8018 6.79943 19.888L2.42636 20.7704L5.44561 24.0547C5.74065 24.3756 5.88147 24.809 5.83142 25.242L5.31923 29.6737L9.37576 27.8172C9.77216 27.6357 10.2279 27.6357 10.6243 27.8172L14.6808 29.6737L14.1686 25.242C14.1186 24.809 14.2594 24.3756 14.5544 24.0547L17.5737 20.7704L13.2006 19.888C12.7733 19.8018 12.4046 19.534 12.1905 19.1542L10 15.2678L7.80948 19.1542Z"
                  fill="#9954F2"
                />
              </svg>
            </div>
            <div>
              <div class="ml-5 ball__title">
                У вас {{ score }} баллов
              </div>
              <div class="ml-5 ball__text">
                За организацию мероприятия уборки в городе получите 20 баллов, <br>
                за участие - 10, за сообщение о скоплении мусора - 5 баллов
              </div>
            </div>
          </div>
          <router-link
            :to="{
              name: 'raiting',
            }"
            v-if="user.status === 1"
          >
            <div class="ml-5 ball__look">
              Смотреть рейтинг пользователей
            </div>
          </router-link>
          <div
            v-else
          >
            <div
              class="ml-5 ball__look"
              @click="OpenLogin"
            >
              Смотреть рейтинг пользователей
            </div>
          </div>
        </div>
      </div>

      <div
        class="cleaning__map-cont"
        v-if="activeTabId.id === 'published'"
      >
        <div
          :class="['cleaning__map', { 'cleaning__map--collapsed': isMapCollapsed }]"
          :key="cleaningId"
        >
          <rir-map-leaflet
            v-if="isShowMap"
            v-model="search"
            :legend-cleaning="isPublicable"
            ref="myMapEvents"
          >
            <l-marker
              v-for="transport of transportData.vehicleMarker.anims"
              v-if="transport.rtype === 'clearing' && isPublicable"
              :lat-lng="[transport.lat, transport.lon]"
            >
              <l-icon
                :icon-size="[40, 120]"
                :icon-url="`data:image/svg+xml;charset=UTF-8,%3csvg width='52' height='24' viewBox='0 0 52 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 8C0 3.58172 3.58172 0 8 0H40C46.6274 0 52 5.37258 52 12V24H8C3.58172 24 0 20.4183 0 16V8Z' fill='%2357A003'/%3e%3cpath d='M14 13.5C14 14.0523 13.5523 14.5 13 14.5C12.4477 14.5 12 14.0523 12 13.5C12 12.9477 12.4477 12.5 13 12.5C13.5523 12.5 14 12.9477 14 13.5Z' fill='white'/%3e%3cpath d='M19 14.5C19.5523 14.5 20 14.0523 20 13.5C20 12.9477 19.5523 12.5 19 12.5C18.4477 12.5 18 12.9477 18 13.5C18 14.0523 18.4477 14.5 19 14.5Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 4C14.4477 4 14 4.44772 14 5H11.6805C10.7028 5 9.8684 5.70683 9.70767 6.6712L9.31954 9H9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V15C9 15.0441 9.00285 15.0875 9.00837 15.13C8.76241 15.2696 8.5793 15.5113 8.52083 15.8044L8.04169 18.2066C7.8566 19.1346 8.56646 20 9.51271 20H22.4948C23.4422 20 24.1523 19.1327 23.9653 18.2039L23.4818 15.8026C23.4228 15.5091 23.2387 15.2674 22.9918 15.1284C22.9972 15.0864 23 15.0435 23 15V11C23.5523 11 24 10.5523 24 10C24 9.44772 23.5523 9 23 9H22.6805L22.2923 6.6712C22.1316 5.70683 21.2972 5 20.3195 5H18C18 4.44772 17.5523 4 17 4H15ZM11 15V12H21V15H17.5015C16.9492 15 16.5015 15.4477 16.5015 16C16.5015 16.5523 16.9492 17 17.5015 17H21.6828L21.8841 18H10.1223L10.3218 17H14.5015C15.0538 17 15.5015 16.5523 15.5015 16C15.5015 15.4477 15.0538 15 14.5015 15H11ZM11.1805 10H20.8195L20.3195 7L11.6805 7L11.1805 10Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 2C34.4772 2 30 6.47715 30 12C30 17.5228 34.4772 22 40 22H50V12C50 6.47715 45.5228 2 40 2ZM40 24H50H52V22V12C52 5.37258 46.6274 0 40 0C33.3726 0 28 5.37258 28 12C28 18.6274 33.3726 24 40 24ZM36 12C36 9.79086 37.7909 8 40 8C42.2091 8 44 9.79086 44 12C44 14.2091 42.2091 16 40 16C37.7909 16 36 14.2091 36 12Z' fill='white'/%3e%3c/svg%3e`"
              />
              <l-popup>
                <balloon-transport
                  :transport="transport"
                  :data-id="transport.id"
                />
              </l-popup>
            </l-marker>
            <l-marker
              v-for="transport of transportData.vehicleMarker.anims"
              v-if="transport.rtype === 'garbage' && isPublicable"
              :lat-lng="[transport.lat, transport.lon]"
            >
              <l-icon
                :icon-size="[40, 120]"
                :icon-url="`data:image/svg+xml;charset=UTF-8,%3csvg width='52' height='24' viewBox='0 0 52 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 8C0 3.58172 3.58172 0 8 0H40C46.6274 0 52 5.37258 52 12V24H8C3.58172 24 0 20.4183 0 16V8Z' fill='%23643302'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 4C10.3431 4 9 5.34315 9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9H23C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7C23 5.34315 21.6569 4 20 4H12ZM21 7H11C11 6.44772 11.4477 6 12 6H20C20.5523 6 21 6.44772 21 7Z' fill='white'/%3e%3cpath d='M11 11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11V16C9 17.3062 9.83481 18.4175 11 18.8293V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19H19C19 19.5523 19.4477 20 20 20C20.5523 20 21 19.5523 21 19V18.8293C22.1652 18.4175 23 17.3062 23 16V11C23 10.4477 22.5523 10 22 10C21.4477 10 21 10.4477 21 11V16C21 16.5523 20.5523 17 20 17H12C11.4477 17 11 16.5523 11 16V11Z' fill='white'/%3e%3cpath d='M18 12C18.5523 12 19 11.5523 19 11C19 10.4477 18.5523 10 18 10H14C13.4477 10 13 10.4477 13 11C13 11.5523 13.4477 12 14 12H18Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 2C34.4772 2 30 6.47715 30 12C30 17.5228 34.4772 22 40 22H50V12C50 6.47715 45.5228 2 40 2ZM40 24H50H52V22V12C52 5.37258 46.6274 0 40 0C33.3726 0 28 5.37258 28 12C28 18.6274 33.3726 24 40 24ZM36 12C36 9.79086 37.7909 8 40 8C42.2091 8 44 9.79086 44 12C44 14.2091 42.2091 16 40 16C37.7909 16 36 14.2091 36 12Z' fill='white'/%3e%3c/svg%3e`"
              />
              <l-popup>
                <balloon-transport
                  :transport="transport"
                  :data-id="transport.id"
                />
              </l-popup>
            </l-marker>
            <v-marker-cluster
              :options="{chunkedLoading: false}"
            >
              <div
                v-for="cleaning in cleaningList"
                v-if="cleaning.type === 'yard' && cleaning.geometry !== null && (cleaning?.geometry?.length || cleaning?.geometry[0]?.length)"
              >
                <l-marker
                  :lat-lng="Array.isArray(cleaning.geometry[0]) ? cleaning.geometry[0] : cleaning.geometry"
                >
                  <l-icon
                    :icon-url="$markerIcon('cleaning_' + getCleaningStatus(cleaning.day, cleaning?.details)).imageHref"
                  />
                  <l-popup
                    :options="{offset: [110, -15]}"
                  >
                    <balloon-card
                      :id="cleaning.id"
                      :title="cleaning.address"
                      :date="cleaning.day"
                      active-tab-id="published"
                      :address="cleaning.description"
                    />
                  </l-popup>
                </l-marker>
              </div>
            </v-marker-cluster>
            <l-polyline
              v-for="cleaning in cleaningList"
              :lat-lngs="cleaning?.geometry || cleaning.geometry2"
              :color="mapStrokeColor(cleaning.day, cleaning.details)"
              v-if="cleaning.type === 'street' && (cleaning?.geometry || cleaning.geometry2)"
            >
              <l-popup
                :options="{offset: [110, 15]}"
              >
                <balloon-card
                  :id="cleaning.id"
                  :title="cleaning.address"
                  :date="cleaning.day"
                  :address="cleaning.description"
                  active-tab-id="published"
                />
              </l-popup>
            </l-polyline>
            <div
              v-for="cleaning in cleaningList"
              v-if="cleaning && (cleaning?.geometry || cleaning.geometry2) && cleaning.type === 'territory'"
            >
              <l-polygon
                :lat-lngs="cleaning?.geometry || cleaning.geometry2"
                :color="mapStrokeColor(cleaning.day, cleaning.details)"
                :fill-color="mapStrokeColor(cleaning.day, cleaning.details)"
                :weight="1"
              >
                <l-popup
                  :options="{offset: [110, 15]}"
                >
                  <balloon-card
                    :id="cleaning.id"
                    :title="cleaning.address"
                    :date="cleaning.day"
                    :address="cleaning.description"
                    active-tab-id="published"
                  />
                </l-popup>
              </l-polygon>
            </div>
          </rir-map-leaflet>
          <button
            class="cleaning__map-collapse-btn feta"
            @click="onCollapsed()"
          >
            <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
        </div>
        <div
          class="r-map__legend"
        >
          <div class="flex align-center">
            <span class="legend__color lebowski--bg" />
            <span class="briscola opacity-72">Ожидается уборка</span>
          </div>

          <div class="flex align-center">
            <span class="legend__color rocky--bg" />
            <span class="briscola opacity-72">Идёт уборка</span>
          </div>

          <div class="flex align-center">
            <span class="legend__color matrix--bg" />
            <span class="briscola opacity-72">Уборка завершена</span>
          </div>
          <div
            class="flex align-center"
          >
            <img
              class="legend__img"

              src="../assets/cleaning.svg"
            />
            <span class="briscola opacity-72">Уборочная техника</span>
          </div>
          <div
            class=" flex align-center"
          >
            <img
              class="legend__img"
              src="../assets/tko.svg"
            />
            <span class="briscola opacity-72">Мусоровозы</span>
          </div>
        </div>
        <div class="mt-3 flex align-center pointer mb-4">
          <r-icon
            icon="info"
            fill="rocky"
          />
          <p
            class="ml-2 sulguni rocky--text"
            @click="clickUploadAuto"
          >
            Если автомобиль эвакуирован
          </p>
        </div>
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
    <upload
      ref="upload"
      @onReload="onReloadActivity"
      @active="activateds"
    />
  </div>
</template>

<script>
import CleaningCard from '@/components/CleaningCard.vue';
import CleaningAddBanner from '@/components/CleaningAddBanner.vue';
import Api from '@/api/Api';
import axios from 'axios';
import ClearCard from '@/components/ClearCard';
import RirMapLeaflet from '@/components/RirMapLeaflet.vue';
import { LMap, LTileLayer, LMarker, LPolyline, LIcon, LPolygon, LPopup } from 'vue2-leaflet';
import L from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import Loader from '@/components/Loader.vue';
import DropDownButtonStatus from '@/components/DropDownButtonStatus.vue';
import DropDownButton from '../components/DropDownButton.vue';
import Upload from '../components/Upload.vue';
import BalloonCard from '../components/BalloonCard.vue';
import RirMap from '../components/RirMap.vue';
import TagCarousel from '../components/TagCarousel.vue';
import BalloonTransport from '../components/BalloonTransport';

export default {
  name: 'Home',
  components: {
    Loader,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LIcon,
    LPopup,
    LPolygon,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    RirMapLeaflet,
    ClearCard,
    CleaningCard,
    CleaningAddBanner,
    TagCarousel,
    RirMap,
    BalloonCard,
    DropDownButton,
    Upload,
    DropDownButtonStatus,
    BalloonTransport
  },

  data() {
    return {
      allowCreate: null,
      allowCreatePublic: false,
      showScore: false,
      isPublicable: false,
      socket: null,
      transportData: {
        vehicleMarker: {
          anims: []
        }
      },
      activePicker: false,
      date: '',
      ball: true,
      datePeriod: null,
      activeTabId: {
        id: 'published',
        title: 'Плановая уборка'
      },
      isShowMap: false,
      score: 'N',
      periodList: [
        { id: 'yesterday', active: false, value: 'Вчера' },
        { id: 'today', active: true, value: 'Сегодня' },
        { id: 'tomorrow', active: false, value: 'Завтра' },
        { id: 'thisWeek', active: false, value: 'Текущая неделя' },
        { id: 'thisMonth', active: false, value: 'Текущий месяц' }
      ],
      periodStatus: [
        { id: 'all', active: true, value: 'Все статусы' },
        { id: 'wait', active: false, value: 'Ожидается уборка' },
        { id: 'progress', active: false, value: 'Идет уборка' },
        { id: 'done', active: false, value: 'Уборка завершена' }
      ],
      selectedStatus: 'Все статусы',
      periodStatusEvent: [
        { id: 'all', active: true, value: 'Все мероприятия' },
        { id: 'me', active: false, value: 'Мои мероприятия' },
        { id: 'participate', active: false, value: 'Участвую в мероприятиях' }
      ],
      selectedStatusEvent: 'Все мероприятия',
      selectedStatusId: 'all',
      selectedStatusEventId: 'all',
      selectedPeriod: 'Сегодня',
      periodListMer: [
        { id: 'today', active: true, value: 'За все время' },
        { id: 'year', active: false, value: 'Текущий год' }
      ],
      selectedPeriodMer: 'За все время',
      filterStartDate: null,
      files: [],
      selectedCategory: 'all',
      selectedCategoryMer: 'all',
      isMapCollapsed: false,
      searchText: null,
      timeout: null,
      countMap: 0,
      map: null,
      user: {},
      coordinates: [47.5259165, 42.1899268],
      cleaningId: 0,
      userId: null
    };
  },
  computed: {
    tabItems() {
      const tabs = [
        {
          id: 'published',
          title: 'Плановая уборка'
        },
        {
          id: 'moderation',
          title: 'Мероприятия',
          countColor: 'fargo'
        }
      ];
      return tabs;
    },
    isModeration() {
      return this.activeTabId.id === 'moderation';
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 420);
      }
    },
    allObjects() {
      const _this = this;
      if (!this.$store.state?.active?.all?.length) return [];

      const objects = this.$store.state.active.all;
      if (this.$store.state.active.all.length && this.$store.state.active.all[0].created) {
        return objects
          .map(el => {
            if (el?.id) {
              let timeTo = null;
              let timeFrom = null;
              if (el.eventForm?.time) {
                const time = el.eventForm?.time.split(' - ');
                timeTo = _this.formatDateHour(time[0]);
                timeFrom = _this.formatDateHour(time[1]);
              }
              return {
                id: el.id,
                timeTo,
                timeFrom,
                address: el.eventForm ? el.eventForm.address : '-',
                dayF: el.eventForm?.datesExtra ? el.eventForm.datesExtra[0] : '-',
                dayFEnd: el.eventForm?.datesExtra ? el.eventForm.datesExtra[el.eventForm.datesExtra.length - 1] : '-',
                title: el.title,
                lan: el.lat ? el.lat : 47.521196,
                lng: el.lng ? el.lng : 42.180914,
                approved: el.approved,
                photo0: el.photo0,
                userId: el.userId ? el.userId : null,
                participants: el.participants ? el.participants : null
              };
            }
          });
      }
    },
    filteredObjects() {
      let list = this.allObjects;
      list = list.filter(el => el.approved === '1');

      if (list && this.selectedCategoryMer === 'cleaning') {
        list = list.filter(el => (
          (el.dayF === '-' ? 'done' : this.getStatus(el.dayF, el.dayFEnd, el.timeTo, el.timeFrom)) === 'progress'));
      }

      if (list && this.selectedCategoryMer === 'cleaning-to') {
        list = list.filter(el => (
          (el.dayF === '-' ? 'done' : this.getStatus(el.dayF, el.dayFEnd, el.timeTo, el.timeFrom)) === 'wait'));
      }

      if (list && this.selectedCategoryMer === 'cleaning-from') {
        list = list.filter(el => (
          (el.dayF === '-' ? 'done' : this.getStatus(el.dayF, el.dayFEnd, el.timeTo, el.timeFrom)) === 'done'));
      }

      if (list && (this.selectedStatusEventId === 'me' || this.selectedCategoryMer === 'me')) {
        list = list.filter(el => (
          (el.userId && this.userId
            ? el.userId.replace(/-/g, '') === this.userId.replace(/-/g, '')
            : false)));
      }

      if (list && (this.selectedStatusEventId === 'participate' || this.selectedCategoryMer === 'participate')) {
        list = list.filter(el => {
          const info = [];
          if (el?.participants && this.userId) {
            el.participants.forEach(item => {
              if (item?.userId) {
                if (item.userId.replace(/-/g, '') === this.userId.replace(/-/g, '')) {
                  info.push(item);
                }
              }
            });
          }
          if (info.length > 0) {
            return true;
          }
          return false;
        });
      }
      if (this.searchText) {
        list = list.filter(
          el => this.onAddress(el.address)
        );
      }
      this.date = new Date().toISOString();

      if (list.length === 0 && this.searchText) {
        this.onCoordinates(this.searchText);
      }

      return list;
    },
    cleaningList() {
      let list = this.$store.state.cleaningList;

      list = list.filter(el => el.id !== '113601');

      if (list && this.selectedCategory !== 'all') {
        list = list.filter(el => el.type === this.selectedCategory);
      }

      if (this.searchText) {
        list = list.filter(
          el => this.onAddress(el.address)
        );
      }
      if (this.selectedStatusId !== 'all') {
        list = list.filter(
          el => this.getCleaningStatus(el.day, el?.details) === this.selectedStatusId
        );
      }
      if (list.length === 0 && this.searchText) {
        this.onCoordinates(this.searchText);
      }

      const items = this.$store?.state?.saveInfo;
      list = list
        .map(el => {
          let index = 0;
          let geometry = el.geometry;
          let geometry2 = el.geometry2;
          if (items && el?.extraIds) {
            if (items.find(els => el.extraIds[0] === els.id) && index <= 0) {
              // eslint-disable-next-line prefer-destructuring
              geometry = items.find(els => el.extraIds[0] === els.id).geometry;
              geometry2 = items.find(els => el.extraIds[0] === els.id).geometry;
              index++;
            }
            return {
              ...el,
              geometry2: (geometry2
                ? (typeof geometry2[0] !== 'string' && typeof geometry2[0][0] !== 'string'
                    ? geometry2
                    : [geometry2]
                )
                : []),
              geometry: (geometry
                ? (typeof geometry[0] !== 'string' && typeof geometry[0][0] !== 'string'
                    ? geometry
                    : [geometry]
                )
                : [])
            };
          } else {
            return {
              ...el,
              geometry: (geometry
                ? (typeof geometry[0] !== 'string' && typeof geometry[0][0] !== 'string'
                    ? geometry
                    : [geometry]
                )
                : [])
            };
          }
        });

      list = list.filter(el => (el !== undefined));

      return list;
    },

    cleaningTypes() {
      return [
        { title: 'Все', active: true, id: 'all' },
        { title: 'Улицы', active: false, id: 'street' },
        { title: 'Дворы', active: false, id: 'yard' },
        { title: 'Территории', active: false, id: 'territory' }
      ];
    },
    cleaningTypesMer() {
      const types = [
        { title: 'Все', active: true, id: 'all' },
        { title: 'Идет уборка', active: false, id: 'cleaning' },
        { title: 'Ожидается уборка', active: false, id: 'cleaning-to' },
        { title: 'Уборка завершена', active: false, id: 'cleaning-from' }
      ];
      if (this.user.status === 1) {
        types.push({
          title: 'Мои мероприятия',
          active: false,
          id: 'me'
        });
        types.push({
          title: 'Участвую',
          active: false,
          id: 'participate'
        });
      }

      return types;
    },

    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  async created() {
    this.changeDate();
    const response = await new Api().systemOptions();
    this.isPublicable = response?.clearingOptions?.isPublicable;
    this.showScore = response?.designOptions?.showScore;

    this.allowCreate = response?.clearingOptions?.allowCreate;

    const responseAllow = await new Api().responseAllow();

    if (responseAllow?.sessions[0]?.authType === 'MAIN'
      && this.allowCreate.includes('smartCity')) {
      this.allowCreatePublic = true;
    }
    if (responseAllow?.sessions[0]?.authType === 'ESIA'
      && this.allowCreate.includes('gosuslugi')) {
      this.allowCreatePublic = true;
    }
    let array = [
      'VK',
      'FB',
      'INSTA',
      'TWTTR',
      'MAILRU',
      'GOOGLE',
      'SCHOOL',
      'YANDEX'
    ];
    if (array.includes(responseAllow?.sessions[0]?.authType)
      && this.allowCreate.includes('socials')) {
      this.allowCreatePublic = true;
    }
    await this.$store.dispatch('user').then(() => {
      this.user = this.$store.state.user;
    });
  },

  async mounted() {
    const api = new Api();
    if (this.$route.path === '/load') {
      this.activeTabId.id = 'moderation';
    }
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    };

    const arr = [];
    const date = new Date();
    const today = date.toLocaleString('ru-Ru', options);
    const tomorrow = date.setDate(date.getDate() + 1).toLocaleString('ru-Ru', options);

    await this.$store.dispatch('getCleaning', { today });
    arr.push(...this.$store.state.cleaningList);
    await this.$store.dispatch('loadActivity', {});
    this.$store.dispatch('myScore').then(result => {
      if (result.myScore) {
        this.score = result.myScore;
      }
    });
    const response = await new Api().systemOptions();
    this.isPublicable = response?.clearingOptions?.isPublicable;
    this.showScore = response?.designOptions?.showScore;

    if (!this.socket) {
      const host = window.location.host === 'localhost:4000' ? 'test.rosatom.city' : window.location.host;
      this.socket = await new WebSocket(`wss://${host}/transport/rest/ws/online?type=clearing`);// connectionCount=0&

      this.socket.onmessage = e => {
        console.log('[message] Данные получены с сервера');
        this.transportData = JSON.parse(e.data);
      };

      this.socket.onclose = function(event) {
        if (event.wasClean) {
          console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
        } else {
          // например, сервер убил процесс или сеть недоступна
          // обычно в этом случае event.code 1006
          console.log('[close] Соединение прервано');
        }
      };

      this.socket.onerror = function(error) {
        console.log(`[error] ${error.message}`);
      };
    }
    const res = await api.getUserInfo();
    this.userId = res?.userId;
  },

  activated() {
    this.isShowMap = true;
  },

  deactivated() {
    this.isShowMap = false;
  },

  methods: {
    formatDateHour(time) {
      let hh = time.split(':')[0];
      if (hh < 10) hh = hh.substring(1);
      const mm = time.split(':')[1];

      return `${hh}:${mm}`;
    },
    onMapCollapsed() {
      this.isMapCollapsed = !this.isMapCollapsed;
      setTimeout(() => {
        this.$refs.myMap.invalidateSize();
      }, 400);
    },
    onCollapsed() {
      this.isMapCollapsed = !this.isMapCollapsed;

      setTimeout(() => {
        this.$refs.myMapEvents.invalidateSize();
      }, 400);
    },
    onCoordinates(item) {
      if (item) {
        axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.$cityName} ${item.trim()}&format=json&lang=ru_RU&kind=house`)
          .then(result => {
            const geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;

            if (geo) {
              this.coordinates = geo.Point?.pos?.split(' ').reverse();
            }
          });
      }
    },
    onAddress(address) {
      let len = 0;
      const arr = this.searchText.toLowerCase().replace(/ +/g, ' ').trim().split(' ');
      arr.forEach(item => {
        if (address.toLowerCase().indexOf(item) >= 0) {
          len++;
        }
      });
      return address
        ? len === arr.length
        : false;
    },
    changeTab() {
      this.centeredMap();
    },
    AddClick() {
      if (this.user.status === 1) {
        this.clickUpload();
      } else {
        this.OpenLogin();
      }
    },
    OpenLogin() {
      window.dispatchEvent(
        new CustomEvent('openModal', {
          detail: {
            name: 'LoginModal'
          }
        })
      );
    },
    mapStrokeColor(date, details) {
      let color = '#D06E0B';
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 17;
      if (details?.selectedDate) {
        if (details?.selectedDate === 'too') {
          from = 0;
          to = 6;
        } else if (details?.selectedDate === 'three') {
          from = 9;
          to = 13;
        } else if (details?.selectedDate === 'four') {
          from = 13;
          to = 17;
        }
      }
      const hours = new Date().getHours();
      if (toDay === date) {
        if (hours < from) {
          color = '#D06E0B';
        } else if (hours > to) {
          color = '#57A003';
        } else {
          color = '#3D75E4';
        }
      }

      if (new Date(date) < new Date() && toDay !== date) {
        color = '#57A003';
      }

      return color;
    },
    changeDate(date) {
      this.date = date;
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      };
      const day = new Date(this.date).toLocaleString('ru-Ru', options);
      if (date) {
        this.datePeriod = day;
      }
      this.$store.dispatch('getCleaning', { day }).then(() => {
        if (this.map) {
          const pointsList = [];
          if (this.cleaningList.length > 0) {
            this.cleaningList.forEach(item => {
              if (Array.isArray(item.geometry[0])) {
                pointsList.push(...item.geometry);
              } else {
                pointsList.push([...item.geometry]);
              }
            });
            this.map.setBounds(window.ymaps.util.bounds.fromPoints(pointsList), {
              checkZoomRange: true,
              zoomMargin: 10,
              duration: 300
            });
          }
        }
      });
      this.activePicker = false;
    },
    activateds() {
      this.isShowMap = true;
    },
    clickUpload() {
      this.$refs.upload.uploadContainer();
    },
    clickUploadAuto() {
      this.$refs.upload.uploadContainerAuto();
    },
    onChangePeriod(e) {
      const toDay = new Date();
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      };

      this.selectedPeriod = e.value;
      this.periodList = this.periodList.map(el => ({ ...el, active: el.value === e.value }));

      if (e.id === 'today') {
        this.$store.dispatch('getCleaning', {});
      } else if (e.id === 'tomorrow') {
        toDay.setDate(toDay.getDate() + 1);
        const day = toDay.toLocaleString('ru-Ru', options);
        this.$store.dispatch('getCleaning', { day });
      } else if (e.id === 'yesterday') {
        toDay.setDate(toDay.getDate() - 1);
        const day = toDay.toLocaleString('ru-Ru', options);
        this.$store.dispatch('getCleaning', { day });
      } else {
        this.$store.dispatch('getCleaning', { [e.id]: 1 });
      }
    },
    onReloadActivity() {
      this.$store.dispatch('loadActivity', {});
    },
    onChangeStatus(e) {
      this.selectedStatusId = e.id;
      this.selectedStatus = e.value;
      this.periodStatus = this.periodStatus.map(el => ({ ...el, active: el.value === e.value }));
    },
    onChangeStatusEvents(e) {
      this.selectedStatusEventId = e.id;
      this.selectedStatusEvent = e.value;
      this.periodStatusEvent = this.periodStatusEvent.map(el => ({
        ...el,
        active: el.value === e.value
      }));
    },
    onChangePeriodMer(e) {
      const toDay = new Date();
      this.selectedPeriodMer = e.value;
      this.periodListMer = this.periodListMer.map(el => ({ ...el, active: el.value === e.value }));

      if (e.id === 'today') {
        this.$store.dispatch('loadActivity', {});
      } else {
        this.$store.dispatch('loadActivity', {
          from: `${toDay.getFullYear() - 1}-${toDay.getMonth()}-${
            toDay.getDay() <= 9 ? '0' : ''}${toDay.getDay()}`
        });
      }
    },
    async onInform() {
      if (this.user.status === 1) {
        await fetch('/ajax.php?action=getAllProblemTypes&asArray=1')
          .then(data => data.json())
          .then(({ all }) => {
            const categories = all.filter(({
                                             disabled,
                                             hidden
                                           }) => !Number(disabled) && !Number(hidden));

            const garbageCategory = categories.find(({ type }) => type.toLowerCase().includes('мусор'));

            const garbageCategoryId = garbageCategory && garbageCategory.typeIdx;

            window.history.pushState(null, null, `/appeals/add?categoryId=${garbageCategoryId}`);
          });
      } else {
        this.OpenLogin();
      }
    },
    getStatus(date, dateEnd, timeTo, timeFrom) {
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 17;
      if (timeTo && timeFrom) {
        from = timeTo.split(':')[0];
        to = timeFrom.split(':')[0];
      }
      let status = 'wait';
      const hours = new Date().getHours();
      if (toDay >= date && toDay <= dateEnd) {
        status = 'progress';
        if (toDay === date) {
          if (hours < from) {
            status = 'wait';
          }
        }

        if (toDay === dateEnd) {
          if (hours < from) {
            status = 'progress';
          }
          if (hours > to) {
            status = 'done';
          }
        }
      }

      if (new Date(dateEnd) < new Date()) {
        status = 'done';
      }

      return status;
    },
    getCleaningStatus(date, details) {
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 17;
      if (details?.selectedDate) {
        if (details?.selectedDate === 'too') {
          from = 0;
          to = 6;
        } else if (details?.selectedDate === 'three') {
          from = 9;
          to = 13;
        } else if (details?.selectedDate === 'four') {
          from = 13;
          to = 17;
        }
      }
      const hours = new Date().getHours();
      if (toDay === date) {
        if (hours < from) {
          return 'wait';
        }
        if (hours > to) {
          return 'done';
        }
        return 'progress';
      }

      if (new Date(date) < new Date() && toDay !== date) {
        return 'done';
      }

      return 'wait';
    },
    getPreviewImg(item) {
      if (item.images?.length && item.images[0]?.url) {
        return item.images[0].url;
      }
      if (item.images?.length) {
        return item.images[0];
      }
      return null;
    },
    loadObjects(typeId) {
      this.$store.dispatch('loadObjects', typeId);
    },
    onChangeType(type) {
      const target = this.isModeration ? 'selectedCategoryMer' : 'selectedCategory';
      if (this[target] !== type.id) {
        this[target] = type.id;
        this.centeredMap();
      }
    },
    centeredMap() {
      if (this.map) {
        const pointsList = [];
        if (this.cleaningList.length > 0 && this.activeTabId.id === 'published') {
          this.cleaningList.forEach(item => {
            if (item?.geometry || item?.geometry2) pointsList.push([item?.geometry ? item.geometry : item.geometry]);
          });
        }
        if (this.filteredObjects.length > 0 && this.activeTabId.id === 'moderation') {
          this.filteredObjects.forEach(item => {
            pointsList.push([item.lan, item.lng]);
          });
        }
        this.$nextTick(() => {
          if (pointsList.length > 0) {
            this.map.setBounds(window.ymaps.util.bounds.fromPoints(pointsList), {
              checkZoomRange: true,
              zoomMargin: 15,
              duration: 300
            });
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.cleaning__cards2 {
  min-width: 400px;
  @media (max-width: 401px) {
    min-width: 1px;
  }
}

.cleaning_date {
  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    height: 44px;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.ball {
  width: 100%;
  height: 124px;
  background: #F9F6FE;
  border-radius: 16px;
  @media screen and (max-width: 599px) {
    height: auto;
    padding-bottom: 16px;
  }

  &__title {
    padding-left: 12px;
    padding-top: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #21043E;
  }

  &__text {
    padding-left: 12px;
    padding-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #21043E;
    opacity: 0.72;
  }

  &__look {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    margin-top: 18px;
    cursor: pointer;
    color: #9954F2;
  }
}

.cleaning {
  display: flex;
  justify-content: space-between;

  &__cards2 {
    width: 41.7%;
  }

  &__map-cont2 {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 648px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
    margin-bottom: 160px;
  }

  &__map2 {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }

  &__map-collapse-btn2 {
    display: none;
  }

  &__input-search {
    position: absolute;
    z-index: 1000000;
  }

  &__cards {
    width: 41.7%;
  }

  &__map-cont {
    // position: sticky;
    // position: -webkit-sticky;
    // top: 32px;
    // width: calc(58.3% - 32px);
    width: 100%;
    // margin-left: 32px;
  }

  &__map {
    position: relative;
    display: flex;
    height: 648px;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }

  &__map-collapse-btn {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .cleaning {
    max-width: 100%;
    flex-direction: column-reverse;

    &__cards2 {
      width: 100%;
    }

    &__map-cont2 {
      position: relative;
      top: unset;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
      margin-bottom: 16px;
    }

    &__map2 {
      height: 300px;
      margin-bottom: 32px;
    }

    &__map--collapsed2 {
      height: 170px;

      ::v-deep .rir-map__legend {
        display: none;
      }
    }

    &__map-collapse-btn2 {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      z-index: 1000;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;

      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dashed #214eb052;
      }
    }

    &__input-search {
      display: flex;
      z-index: 10000000;
    }

    &__cards {
      width: 100%;
    }

    &__map-cont {
      position: relative;
      top: unset;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }

    &__map {
      height: 360px;
      margin-bottom: 32px;
    }

    &__map--collapsed {
      height: 170px;
    }

    &__map-collapse-btn {
      z-index: 1000;
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      z-index: 1000;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;

      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dotted #214eb052;
      }
    }
  }
}

.mobile_grid {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  ::v-deep .cleaning_date {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 599px) {
  .cleaning {
    &__map--collapsed {
      height: 170px;

      ::v-deep .rir-map__legend {
        display: none;
      }
    }
  }
}

.h-100 {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.legend {
  &__color {
    margin-right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    flex-shrink: 0;
    position: relative;
  }

  &__img {
    margin-right: 6px;
    height: 16px;
    flex-shrink: 0;
  }
}

.r-map {
  &__legend {
    z-index: 1000;
    padding: 8px 12px;
    background: var(--rir-amelie);
    display: grid;
    grid-template-columns: 20% 15% 20% 25% 20%;
    left: 16px;
    bottom: 20px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 599px) {
  .r-map {
    &__legend {
      grid-template-columns: 44% 56%;
      grid-template-rows: 40px 40px 40px;
    }
  }
}
</style>
<style>
.RButtonAction {
  background-color: transparent !important;
}
</style>
