<template>
  <div class="add-object">
    <div
      class="add-object__formData"
    >
      <h1 class="camembert mb-4">
        Участники мероприятия
      </h1>
      <div
        class="sulguni metropolis--text mb-6"
      >
        Участники: {{
            (participants?.length ? participants?.length : 0)
            + (!userIdEMKA ?  1 : 0)
        }}
      </div>
      <div
        v-if="user"
        class="flex mb-8"
      >
        <picture
          class="mr-4"
        >
          <img
            :src="'/mob_ajax.php?getAvatarForUser=' + user['-id']"
            class="icon-from mr-4"
          >
        </picture>
        <div>
          <div
            class="sulguni"
          >
            {{ user.fio }}
          </div>
          <div
            class="mozzarella metropolis--text"
          >
            Организатор
          </div>
        </div>
      </div>
      <div
        v-for="(item,index) in participants"
        v-if="participants && user.userIdEMKA !== item.userIdEMKA"
        class="flex mb-5"
      >
        <picture
          class="mr-4"
        >
          <img
            :src="'/mob_ajax.php?getAvatarForUser=' + item.userId"
            class="icon-from mr-4"
          >
        </picture>
        <div>
          <div
            class="sulguni"
          >
           {{ item.fio }}
          </div>
          <div
            class="mozzarella metropolis--text"
          >
            Участник
          </div>
        </div>
      </div>
      <r-button
        v-if="add && wait"
        class="flex-1"
        @click="addUser()"
        icon="add"
        title="Присоединится"
        width="wide"
      >

      </r-button>
      <r-button
        v-else-if="!add && wait"
        class="flex-1"
        @click="addUser()"
        title="Отказатся от участия"
        width="wide"
        type="secondary"
      >
      </r-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserModal',
  props: {
    participants: Array,
    user: Object,
    add: Boolean,
    wait: Boolean,
    userIdEMKA: Boolean
  },
  data() {
    return {
    };
  },
  methods: {
    addUser() {
      this.$store.dispatch('getUserAdd', this.$route.params.id).then(result => {
        location.reload();
      }, error => {
        console.error(error);
      });
    },
    close() {
      document.querySelectorAll('.RModal__close').forEach(item => {
        item.click();
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.icon-from {
  margin-top: -12px;
  width: 56px;
  border-radius: 28px;
  border: 3px solid #ffffff;
}

.add-object {
  &__formData {
    text-align: left;
    max-width: 618px;
    margin: 0 auto;
  }
  &__map {
    height: 200px;
    background-color: #eee;
  }
  &__success {
    position: absolute;
    top: calc(50% - 164px);
    max-width: 624px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
  }
  &__contacts {
    .rir-input:last-child {
      margin-left: 16px;
    }
  }
}

.m-auto {
  margin: 0 auto;
}

.popover {
  position: relative;
}

.popover__content {
  position: absolute;
  right: 6px;
  bottom: calc(100% + 6px);
  max-width: 240px;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 2px #ccc);
  filter: drop-shadow(0px 1px 2px #ccc);
  z-index: 100;
  &::before {
    content: '';
    width: 12px;
    height: 6px;
    background-color: #fff;
    position: absolute;
    right: 12px;
    bottom: -5px;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }
}

@media screen and (max-width: 1023px) {
  .add-object {
    &__formData {
      max-width: 350px;
    }
  }
}

@media screen and (max-width: 599px) {
  .add-object {
    &__formData {
      max-width: 272px;
    }
    &__contacts {
      flex-direction: column;
      .rir-input:last-child {
        margin-top: 24px;
        margin-left: unset;
      }
    }
  }
}

</style>
