<template>
  <r-popover
    click-close
    v-click-outside="clickOutside"
    class="mb-3"
    position="right-bottom"
  >
    <template #activator="{ on }">
      <r-button-action
        v-on="on"
        :title="title"
        v-model="isOpen"
        is-arrow
      />
    </template>
    <template #content>
      <div>
        <div
          :class="['item', { 'item--active': item.active }]"
          @click="!item.disabled && selectItem(item)"
          v-for="item in items"
          :key="item.id"
        >
          <r-icon
            v-if="item.icon"
            class="mr-3"
            :icon="item.icon"
          />
          <span class="sulguni">{{ item.value }}</span>
        </div>
      </div>
    </template>
  </r-popover>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownButton',
  directives: {
    ClickOutside
  },
  props: {
    items: {
      tyep: Array,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      title: 'За все время'
    };
  },

  methods: {
    onChangeShow(val) {
      this.isOpen = val;
    },
    clickOutside() {
      this.isOpen = false;
    },
    selectItem(item) {
      this.title = item.value;
      this.$emit('select', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}

.list {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;
  right: 0;
  box-shadow: 0 9px 24px rgba(17, 48, 121, 0.18);
  z-index: 10000;
  border-radius: 8px;
}

.item {
  display: flex;
  white-space: nowrap;
  align-items: center;
  opacity: 0.72;
  padding: 14px 16px;
  position: relative;
  &:hover {
    opacity: 1;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      background-color: #3d75e4;
      height: calc(100% - 8px);
      width: 4px;
      top: 4px;
      left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.item--active {
  opacity: 1;
  &::before {
    content: '';
    position: absolute;
    background-color: #3d75e4;
    height: calc(100% - 8px);
    width: 4px;
    top: 4px;
    left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

::v-deep .rir-drop-down__content {
  top: 100%;
  left: 0;
}
</style>
