<template>
  <div v-if="showScore">
    <router-link
      class="flex align-center sulguni mb-7 print"
      :to="{
        name: 'load',
      }"
    >
      <r-icon
        class="mr-2 mt-2px"
        icon="arrow-left"
        fill="rocky"
      />
      Назад
    </router-link>
    <loader v-if="isLoading" />
    <div
      v-else
      class="culture mt-8"
    >
      <div class="culture__cards">
        <div class="title-text flex flex-wrap gap-x-4 gap-y-4 align-center">
          <h1>Рейтинг пользователей</h1>
          <div
            @click="printCSVtoFile"
            v-if="user.user ? user.user.adminAccess : false"
          >
            <span
              class="flex align-center sulguni rocky--text pointer"
            >
              <r-icon
                class=" mr-2"
                icon="load"
              />
              Скачать
            </span>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="culture__map-cont mt-2">
          <div class="mr-10 flex">
            <div class="culture__user_number">
              <div
                class="mt-4 text snatch--text"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="8"
                  viewBox="0 0 6 8"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.37829 7.83503C3.16934 8.05499 2.83058 8.05499 2.62163 7.83503L0.156652 5.96155C-0.0522928 5.74158 -0.0522926 5.38494 0.156652 5.16498C0.365596 4.94501 0.704362 4.94501 0.913306 5.16498L2.99996 6.64017L5.08664 5.16497C5.29558 4.94501 5.63435 4.94501 5.84329 5.16497C6.05224 5.38494 6.05224 5.74158 5.84329 5.96154L3.37829 7.83503Z"
                    fill="#C0D6F6"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.6217 0.164974C2.83064 -0.0549915 3.16939 -0.0549915 3.37832 0.164975L5.8433 2.03846C6.05223 2.25842 6.05223 2.61506 5.8433 2.83503C5.63436 3.05499 5.29561 3.05499 5.08668 2.83503L3.00001 1.35983L0.913324 2.83503C0.704388 3.05499 0.365637 3.05499 0.156702 2.83503C-0.0522339 2.61506 -0.0522338 2.25842 0.156702 2.03846L2.6217 0.164974Z"
                    fill="#C0D6F6"
                  />
                </svg>
              </div>
            </div>
            <div class="culture__user">
              <div class="mt-4 text__up">
                Пользователи
              </div>
            </div>
            <div class="culture__user_count">
              <div class="mt-4 text__up">
                Баллы
              </div>
            </div>
          </div>
          <div
            class="mr-10 flex"
            v-for="(item, index) in items"
            v-if="index >= 1"
          >
            <div class="culture__user_number">
              <div
                class="mt-4 text"
                :style="top(index)"
              >
                {{ index }}
              </div>
            </div>
            <div class="culture__user flex">
              <div class="mt-4">
                <img
                  :src="item.userIco"
                  class="mr-2 mt-2 w-32 br-16"
                >
              </div>
              <div class="mt-4 text">
                {{ item.username }}
              </div>
            </div>
            <div class="culture__user_count">
              <div class="mt-4 text">
                {{ item.score }}
              </div>
            </div>
          </div>
        </div>
        <div class="cleaning__map-cont mt-2 bottom">
          <div class="time-mer mb-6">
            Как получить баллы?
          </div>
          <div class="title__ball">
            20 баллов
          </div>
          <div class="title__text mt-3">
            за организацию мероприятия уборки в городе
          </div>

          <div
            class="title__link mt-3"
            @click="AddClick"
          >
            Предложить мероприятие
          </div>

          <div class="title__ball mt-6">
            10 баллов
          </div>
          <div class="title__text mt-3">
            за участие в уже созданном мероприятии
          </div>
          <router-link
            :to="{
              name: 'Cleaning',
            }"
          >
            <div class="title__link mt-3">
              Найти подходящее
            </div>
          </router-link>
          <div class="title__ball mt-6">
            5 баллов
          </div>
          <div class="title__text mt-3">
            за сообщение о скоплении мусора
          </div>
          <div
            class="title__link mt-3 pointer"
            @click="onInform"
          >
            Сообщить
          </div>
          <div class="mt-8 mb-8">
            <h2 class="taleggio title__share mb-3">
              Поделиться
            </h2>
            <share/>
          </div>
        </div>
      </div>
    </div>
    <upload
      ref="upload"
      @onReload="onReloadActivity"
    />
  </div>
  <Page404 v-else>
  </Page404>
</template>
<script>
import Loader from '@/components/Loader.vue';
import Api from '../api/Api';
import Share from '../components/Share.vue';
import Upload from '../components/Upload';
import Page404 from "@/views/Page404.vue";

export default {
  components: {
    Page404,
    Loader,
    Share,
    Upload
  },
  props: {},
  data() {
    return {
      showScore: false,
      items: [],
      user: {}
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  created() {
    this.$store.dispatch('user').then(() => {
      this.user = this.$store.state.user;
    });
  },
  async mounted() {
    const response = await new Api().systemOptions();
    this.showScore = response?.designOptions?.showScore;

    this.$store.dispatch('userRating').then(result => {
      this.items = result.all
        .map(el => ({
          score: el.score,
          username: el.username,
          userId: el.userId,
          userIco: `/mob_ajax.php?getAvatarForUser=${el.userId}`
        }));
    });
  },
  methods: {
    onReloadActivity() {
      this.$store.dispatch('loadActivity', {});
    },
    AddClick() {
      if (this.user.status === 1) {
        this.clickUpload();
      } else {
        this.OpenLogin();
      }
    },
    clickUpload() {
      this.$refs.upload.uploadContainer();
    },
    OpenLogin() {
      window.dispatchEvent(
        new CustomEvent('openModal', {
          detail: {
            name: 'LoginModal'
          }
        })
      );
    },
    printCSVtoFile() {
      this.$store.dispatch('getCsv').then(csv => {
        const download = document.createElement('a');
        download.href = 'data:text/csv;charset=utf-8;content-disposition=attachment;filename=file,' + `\uFEFF${csv}`;
        download.download = 'rating.csv';
        download.style.display = 'none';
        download.id = 'download';
        document.body.appendChild(download);
        document.getElementById('download').click();
        document.body.removeChild(download);
      });
    },
    async onInform() {
      const api = new Api();
      const res = await api.getUserInfo();
      if (res?.userId > 0 && res?.fio !== '') {
        const url = '/appeals/add?category=2&title=Неубранный мусор&redirectLink=cleaning';

        window.history.pushState(null, null, url);
      } else {
        window.dispatchEvent(
          new CustomEvent('openModal', {
            detail: {
              name: 'LoginModal',
              props: {
                title: 'Войдите или зарегистрируйтесь, чтобы мы могли связаться с вами',
                text: 'Если вы не зарегистрированы, учётная запись будет создана автоматически'
              }
            }
          })
        );
      }
    },
    top(index) {
      if (index === 1) {
        return 'color: #E9A35D;';
      }
      if (index === 2) {
        return 'color: #A2ABBE;';
      }
      if (index === 3) {
        return 'color: #9E4F00;';
      }
      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom {
  display: table;
  padding-top: 12px;
}

.title-text {
  width: calc(70% + 40px);
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  &__ball {
    font-weight: 800;
    line-height: 24px;
    color: #04153E;
  }
  &__text {
    line-height: 24px;
    color: #04153E;
    opacity: 0.72;
  }
  &__link {
    cursor: pointer;
    font-weight: 500;
    line-height: 20px;
    color: #3D75E4;
  }
}

.title__share {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #04153E;
  text-align: left;
}

.time-mer {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #04153E;
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.culture {
  max-width: 100%;
  flex-direction: column-reverse;

  &__cards {
    width: 100%;
  }

  &__map-cont {
    position: relative;
    top: unset;
    height: unset;
    padding: 0;
    margin-left: unset;
    width: 90%;
    min-height: 400px;
  }
  &__user_number {
    width: 74px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #E4EDFB;
    height: 56px;
  }
  &__user {
    width: calc(100% - 148px);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #E4EDFB;
    height: 56px;
  }
  &__user_count {
    width: 74px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #E4EDFB;
    height: 56px;
  }
}

@media screen and (max-width: 1080px) {
  .title-text {
    width: 100%;
  }
  .culture {
    &__map-cont {
      position: relative;
      top: unset;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
      min-height: 300px;
    }
  }
}

@media screen and (max-width: 599px) {
  .title-text {
    width: 100%;
  }
  .culture {
    &__map-cont {
      position: relative;
      top: unset;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
      min-height: 300px;
    }
  }
}

cleaning {
  display: flex;
  justify-content: space-between;
  &__map-cont {
    width: 100%;
  }
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  &__up {
    font-size: 13px;
    line-height: 16px;
    color: #04153E;
    opacity: 0.48;
  }
}

.gap-x-4 {
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  grid-column-gap: 1rem;
  column-gap: 1rem;
}

.gap-y-4 {
  -webkit-row-gap: 1rem;
  -moz-row-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: auto 30%;
}
@media(max-width: 1100px){
  .grid {
    display: unset;
    grid-template-columns: unset;
  }
}
@media(max-width: 599px){
  .culture__map-cont{
    margin-right: 0;
    div[class*="culture__user"]{
      float: none;
    }
    .mr-10,
    .mt-10.mr-10{
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;
      display: flex;
      width: 100%;
      align-items: center;
    }
    .culture__user_count{
      width: 50px;
      height: 65px;
    }
    .culture__user{
      width: 100%;
      height: 65px;
      .text{
        margin-bottom: 8px;
      }
    }
  }
  .culture__user_number{
    width: 38px; height: 65px;
  }
}

.w-32 {
  width: 32px;
}

.br-16 {
  border-radius: 16px;
}

.pointer {
  cursor: pointer;
}
</style>
