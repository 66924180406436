<template>
  <div
    :class="['r-map', { 'r-map--fixed': !isCollapsed }]"
    ref="map"
  >
    <div class="r-map__search">
      <r-input
        type="map"
        v-if="searchIn"
        class="cleaning__input-map-search"
        label="Поиск по адресу"
        :value="value"
        @input="$emit('input', $event)"
        after-icon="search"
      />
    </div>
    <div :key="count">
      <l-map
        ref="myMap"
        :crs="layer.crs"
        @click="clickMap()"
        :options="{ zoomControl: false, editable: true, attributionControl: false }"
        class="absolute-wh"
        :center="center || $cityCenter"
        :zoom="mapZoom"
      >
        <l-tile-layer
          :url="layer.url"
          :attribution="layer.attribution"
        />
        <l-control-attribution position="bottomright" prefix="">
        </l-control-attribution>
        <slot />
      </l-map>
    </div>

    <div
      v-show="control"
      class="r-map__controls"
    >
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        v-if="collapseBtn"
        class="mb-4 amelie"
        :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
        fill="rocky"
        icon-size="20"
        @click="onCollapseMap"
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        icon="add"
        class="amelie"
        fill="rocky"
        icon-size="20"
        @click="onZoom(1)"
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        class="mt-1 mb-4 amelie"
        icon="remove"
        fill="rocky"
        icon-size="20"
        @click="onZoom(-1)"
      />
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LControlAttribution } from 'vue2-leaflet';
import L from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import '@/plugins/ui/MarkerCluster.Default.css';
import '@/plugins/ui/leaflet.css';

export default {
  name: 'RirMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlAttribution,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  props: {
    search: {
      type: Boolean,
      default: false
    },
    searchIn: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, null],
      default: null
    },
    control: {
      type: Boolean,
      default: true
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: false
    },
    legend: {
      type: Boolean,
      default: true
    },
    legendCleaning: {
      type: Boolean,
      default: false
    },
    center: {
      type: Array
    },
    zoom: {
      type: Number,
      default: 14
    },
    maxZoom: {
      type: Number,
      default: 22
    },
    minZoom: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      layer: {
        url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.28-0-b210701140430&x={x}&y={y}&z={z}&scale=2&lang=ru_RU',
        subdomains: '1,2,3,4',
        attribution: `
        <div style="display: flex; align-items: center;">© Яндекс
          <a style="margin-left: 4px; color: black;" href='https://yandex.ru/legal/maps_termsofuse/?lang=ru'>
              Условия использования
          </a>
          <img style="margin: 0 4px;" src="${this.$storage}/common/icons/other/map/yandex-logo.svg"/>
        </div>`,
        crs: L.CRS.EPSG3395
      },
      count: 0,
      isYmapsReady: true,
      mapControls: [], // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        // restrictMapArea: [
        //   [54.982611, 43.223491],
        //   [54.817007, 43.482356]
        // ],
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },

      clusterOptions: {
        main: {
          //   clusterDisableClickZoom: true,
          clusterOpenBalloonOnClick: false,

          // Иконка кластера
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]

          //   Шрифт кластера
          //   clusterIconContentLayout:
          //     '<p style="color: #FFFFFF; font-weight: bold;font-size: 24px; position: absolute;">$[properties.geoObjects.length]</p>'
        }
      },

      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      isCollapsed: true
    };
  },

  mounted() {
    document.addEventListener("DOMContentLoaded", function() {
      const flag = document.querySelector('.leaflet-attribution-flag');
      const parent = flag.parentNode;
      parent.removeChild(flag);
    });

    this.$emit('mapInit', this.$refs.myMap);
  },
  methods: {
    invalidateSize() {
      this.$refs.myMap.mapObject.invalidateSize();
    },
    clickMap() {
      this.$emit('clickMap', this.$refs.myMap);
    },
    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },
    onCollapseMap() {
      this.isCollapsed = !this.isCollapsed;
      this.count++;
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  &__search {
    z-index: 420;
    position: absolute;
    border-radius: 8px;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
    > * {
    }
  }
  &__controls {
    z-index: 1000;
    display: block !important;
    position: absolute;
    top: 16px;
    right: 16px;
    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }
    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }
  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    opacity: 1;
  }

  &__legend {
    z-index: 1000;
    padding: 8px 12px;
    background: var(--rir-amelie);
    position: absolute;
    left: 16px;
    bottom: 20px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 1023px) {
  .r-map {
    &__search {
      display: none;
    }
  }
}

// yaMap
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}
::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}

// Стилизация PopUp'a
::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
  transform: translate(66px, -10px);
  padding: 0;
  margin: 0;
}

// Скрытие "хвостика" PopUp'a
::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}

.absolute-wh {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>

<style>
.RInput.cleaning__input-map-search.map.eluno {
  box-shadow: 0 0 0 0 !important;
}
/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* HTML элемент в котором лежит цифра (кол-во элементов кластера) */
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

/* Скрытие кнопки на весь экран */
.ymaps-2-1-79-float-button {
  display: none !important;
}
.RButton.helike {
  padding: 12px !important;
}
/* Скрытие кнопки на весь экран */
.ymaps-2-1-79-float-button {
  display: none !important;
}
.leaflet-attribution-flag {
  display: none !important;
}
.leaflet-control-attribution svg {
  width:0px !important;
}
</style>
