<template>
  <div
    v-show="isShow"
    class="banner relative flex align-center"
    :style="bgStyle"
  >
    <r-icon
      icon="gift"
      fill="gentlemen"
      size="32"
    />

    <div class="ml-3">
      <h4 class="bryndza">
        Получайте баллы
      </h4>
      <p class="mozzarella opacity-72 text-extraction">
        За организацию мероприятия уборки в городе получите 20 баллов, а за участие — 10
      </p>
    </div>
    <r-icon
      class="banner__close-icon"
      icon="close"
      fill="extraction"
      @click.native="isShow = false"
    />

    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: true
    };
  },

  computed: {
    bgStyle() {
      return {
        background: `url(${window.__CONFIG__.storage}/${window.__CONFIG__.appFolder}/bg_banner.png) no-repeat
      right, var(--rir-intouchables)`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  min-height: 80px;
  padding: 16px 24px;
  border-radius: 16px;
  margin-top: 12px;
  text-align: left;

  &__close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    opacity: 0.48;
    &:hover {
      cursor: pointer;
      opacity: unset;
    }
  }
}

@media screen and (max-width: 600px) {
  .banner {
    background-position-x: calc(100% + 60px);
    &__title {
      font-size: 16px;
      line-height: 20px;
      max-width: 180px;
    }
  }
}
</style>
