<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parent align-center">
          <div class="container-upload">
            <h1 class="ricotta mb-6">
              Итоги проведения мероприятия
            </h1>
            <r-textarea
              class="mb-6"
              value=""
              label="Описание"
              :rows="6"
              :max-rows="6"
              v-model="items['commentResult']"
            />

            <upload-file-input
              :count-file="1"
              upload-url="/uploadfile/index.php"
              :value="filesUpload"
              :accept-file="['image/png', 'image/jpeg', 'image/gif']"
              @input="uploadFile"
              sub-title="или перетяните сюда файл объёмом не более 50 МБайт"
            />
            <div
              class="flex mt-2 mb-4"
              style="width: 100%;display: inline-block;"
            >
              <r-button
                title="Отправить"
                width="wide"
                class="flex-1"
                @click="save()"
                :disabled="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import UploadFileInput from '@/components/UploadFileInput.vue';
import RirMap from './RirMap.vue';

Vue.use(vClickOutside);

export default {
  name: 'UploadItog',
  components: {
    RirMap,
    UploadFileInput
  },
  props: {
    item: {
      type: Array
    }
  },
  data() {
    return {
      image: 0,
      upload: false,
      items: [],
      filesUpload: []
    };
  },
  created() {
    this.items.photoResult0 = '';
  },
  methods: {
    clickEnd() {
      this.upload = false;
      this.uploadEnd = false;
    },
    uploadContainer() {
      this.upload = true;
    },
    formattedFiles(files) {
      const formattedFiles = [];
      console.log(files);
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.name, // .split('.')[0]
            size: el?.size,
            icon: false,
            url: el.url
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });
      return formattedFiles;
    },
    uploadFile(files) {
      const _this = this;
      if (!files?.length) {
        _this.filesUpload = [];
        _this.items.photoResult0 = '';
        return;
      }
      _this.filesUpload = _this.formattedFiles(files);
      _this.items.photoResult0 = _this.filesUpload[0].url;
      console.log(_this.items.photoResult0);
    },
    save() {
      const v = this;
      console.log(v.items.commentResult);
      v.item[0].commentResult = v.items.commentResult;
      v.item[0].photoResult0 = v.items.photoResult0;
      console.log(v.item);
      const it = v.item;

      this.$store.dispatch('loadSaveNew', it[0]).then(result => {
        v.upload = false;
        v.uploadEnd = true;
        location.reload();
      }, error => {
        console.error(error);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.video {

  &__login {
    width: calc(50% - 12px);
  }

  &__pass {
    width: calc(50% - 12px);
    float: right;
  }
}

.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}
.align-centerEnd {
  align-items: center;
  float: left;
  margin-top: 5px;

}

.upload {
  background-color: white;
  z-index: 1000;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}
.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 45%;
  margin-left: 31%;
  margin-top: -150px;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.parentEnd {
  display: block;
  position: absolute;
  width: 70%;
  height: 400px;
  top: 50%;
  margin-left:28%;
  margin-top: -150px;
}

.container-uploadEnd {
  width: 55%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}
.culture {
  max-width: 100%;
  flex-direction: column-reverse;

  &__cards {
    width: 100%;
  }

  &__map-cont {
    position: relative;
    top: unset;
    height: unset;
    padding: 0;
    margin-left: unset;
    width: 100%;
  }

  &__map {
    height: 160px;
    margin-bottom: 32px;
  }

  &__map--collapsed {
    height: 112px;
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: block;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dashed #214eb052;
    }
  }
}

.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}
</style>
